import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import CardDeck from 'react-bootstrap/CardDeck';
import Container from 'react-bootstrap/Container';
import Addition from "./Addition";
import {Button} from "react-bootstrap";

type MyProps = {};
type MyState = {};

class App extends React.Component<MyProps, MyState> {

  render() {
    const that = this
    return (
      <Container>
        <Button variant="primary" size="lg" onClick={() => window.location.reload()} block>Reload</Button>
        <CardDeck>
          <Addition/>
          <Addition/>
        </CardDeck>
        <CardDeck>
          <Addition/>
          <Addition/>
        </CardDeck>
      </Container>
    );
  }
}

export default App;
